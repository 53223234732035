// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_link__xIhZh {
  font-weight: 600;
  font-size: 1.146vw;
  line-height: 24px;
  color: #fafbfcb3;
  cursor: pointer;
  padding: 18px 10px;
  margin: 0 19px;
  margin-top: -3px;
  border-top: 4px solid transparent;
  box-sizing: border-box;
}

.Header_link__xIhZh:hover {
  color: #fff;
}

.Header_member-login-btn__hXAKY {
  height: 58px;
  border-radius: 8px;
  padding: 8px 40px 8px 40px;
  /* background: linear-gradient(90deg, #6cd4ee 100%, #4cbddb 100%); */
  background-image: linear-gradient(180deg, #6cd4ee 0%, #4cbddb 100%);
  font-weight: 600;
  font-size: 1.146vw;
  line-height: 33px;
  color: #fafbfc;
}

.Header_contact-link__4Mt7o:hover {
  color: #536ce8;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,iCAAiC;EACjC,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,0BAA0B;EAC1B,oEAAoE;EACpE,mEAAmE;EACnE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".link {\n  font-weight: 600;\n  font-size: 1.146vw;\n  line-height: 24px;\n  color: #fafbfcb3;\n  cursor: pointer;\n  padding: 18px 10px;\n  margin: 0 19px;\n  margin-top: -3px;\n  border-top: 4px solid transparent;\n  box-sizing: border-box;\n}\n\n.link:hover {\n  color: #fff;\n}\n\n.member-login-btn {\n  height: 58px;\n  border-radius: 8px;\n  padding: 8px 40px 8px 40px;\n  /* background: linear-gradient(90deg, #6cd4ee 100%, #4cbddb 100%); */\n  background-image: linear-gradient(180deg, #6cd4ee 0%, #4cbddb 100%);\n  font-weight: 600;\n  font-size: 1.146vw;\n  line-height: 33px;\n  color: #fafbfc;\n}\n\n.contact-link:hover {\n  color: #536ce8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `Header_link__xIhZh`,
	"member-login-btn": `Header_member-login-btn__hXAKY`,
	"contact-link": `Header_contact-link__4Mt7o`
};
export default ___CSS_LOADER_EXPORT___;
